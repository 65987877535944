<template>

    <v-row>
      <v-col lg="6">
        <h2>Welcome to the SaaS Builder Community</h2>
        <p>The SaaS Builder website is a place for all builders to learn and share experiences. The learning experience and shared knowledge is our primary focus.</p>
        <v-btn class="success white--text" rounded>Get Started </v-btn>
        <v-btn class="indigo white--text" rounded>Watch Video
          <v-icon>mdi-play-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
</template>

<script>

export default {
  name: 'Home'
}
</script>

