<template>
  <header>
    <v-app-bar
        style="padding: 0px 10px"
        app
        flat
        color=#FF7043
    >

      <v-toolbar-title @click="$router.push('/')">
        <v-icon x-large color="white darken-2">mdi-account-hard-hat</v-icon>
        <span class="font-weight-light white--text text-h5">SaaS</span>
        <span class="font-weight-bold black--text ml-1">Builder</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-list class="deep-orange lighten-1 d-flex align-center">

        <v-list-item
            v-for="links in headerLinks"
            :key="links.title"
            :to="links.to"
        >
          <v-list-item-content>
            <v-list-item-title>{{ links.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-app-bar>
  </header>
</template>

<script>

export default {
  data: () => ({
    drawer: false,

    headerLinks: [
      {title: 'Home', icon: 'mdi-home', to: '/'},
      {title: 'About Us', icon: 'mdi-account-group', to: '/About'},
      {title: 'Patterns', icon: 'mdi-vector-square-edit', to: '/Patterns'},
      {title: 'Reference Architectures', icon: 'mdi-vector-combine', to: '/ReferenceArchitectures'},
      {title: 'Forum', icon: 'mdi-forum', to: '/Forum'},
    ],
  })
}
</script>
