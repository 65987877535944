<template>
  <v-app>
    <Header/>
    <v-main class="bg">
      <v-container class="my-5">
        <router-view ></router-view>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>

<style>
.bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url('~@/assets/background.png');
  background-position: center center;
  background-attachment: fixed;
  background-repeat: repeat;
  background-size: cover;
}
</style>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'App',

  components: {Header, Footer},

  data: () => ({
    //
  }),
};
</script>
