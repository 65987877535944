<template>
  <v-footer 
  app
  height=35px
  class="deep-orange lighten-1 text-center"
  padless
  >
    <v-row
        justify="center"
        no-gutters
    >
      <v-card
          flat
          tile
          width="100%"
          class="deep-orange lighten-1 pa-0"
      >
          <v-card-text class="black--text pa-0">
            {{ new Date().getFullYear() }} — <strong>SaaS builder</strong>
            <v-btn
                v-for="icon in icons"
                :key="icon"
                class="mx-4 white--text justify-center"
                icon
            >
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>

            <strong>Coming Soon!</strong>
          </v-card-text>
      </v-card>
    </v-row>
  </v-footer>
</template>

<script>

export default {
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram'
    ]
  })
}
</script>
